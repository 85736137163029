<messages>["./Domain"]</messages>

<!--
================================================================================
  Template (HTML)
================================================================================
-->

<template>
  <base-layout mw2>
    <v-col lg="5" cols="12">
      <v-card>
        <form
          novalidate
          @submit.prevent="submit()">
          <v-card-title primary-title>
            <div>
              <div v-t="'view.label.check.domaindata'" class="text-h5"/>
              <div
                v-t="'view.label.inquire.subtitle'"
                class="cgwng-subheading"/>
            </div>
          </v-card-title>
          <v-card-text>
            <v-row>
              <v-col
                v-if="!hasClient"
                cols="12">
                <client-select
                  v-model="clientId"
                  required
                  :v="$v.clientId"/>
              </v-col>
              <v-col cols="12">
                <v-text-field
                  v-model.trim="domainName"
                  class="required"
                  clearable
                  name="domains"
                  spellcheck="false"
                  :loading="isLoadingRegistry"
                  :label="$t ('filter.domainName')"
                  :error-messages="[...validationErrors (
                    'domainName', {
                      required: 'general.invalid.domainName'
                    }), ...additionalError]"
                  @blur="$v.domainName.$touch"/>
              </v-col>
              <v-col cols="12">
                <v-text-field
                  v-model="authInfo"
                  :label="$t ('view.label.authInfo')"
                  clearable/>
              </v-col>
            </v-row>
          </v-card-text>
          <v-card-actions>
            <v-spacer/>
            <v-btn
              v-t="'general.button.reset'" text
              @click="reset"/>
            <v-btn
              v-t="'general.button.submit'" color="primary"
              type="submit"/>
          </v-card-actions>
        </form>
      </v-card>
    </v-col>

    <v-slide-y-transition>
      <v-col
        v-if="result || isLoading"
        lg="7" cols="12">
        <v-alert
          v-if="error"
          v-t="'view.label.error'"
          type="error"/>
        <order-response
          :waiting="isLoading"
          :response="result"/>
      </v-col>
    </v-slide-y-transition>
  </base-layout>
</template>

<!--
================================================================================
  Logic (JavaScript)
================================================================================
-->

<script>
  import BaseLayout from '@/app/core/components/BaseLayout'
  import OrderResponse from '@/app/pages/Order/components/OrderResponse'
  import ClientSelect from '@/app/core/components/ClientSelect'

  import {mapActions, mapGetters} from 'vuex'
  import {required, requiredIf} from 'vuelidate/lib/validators'
  import validationMixins from '@/app/core/mixins/ValidationHelper'

  const PROCESSING_ERROR = 'error/server/processing'
  const PAYLOAD_PROPERTY = 'payload'

  export default {
    name: 'DomainInquire',

    components: {
      BaseLayout,
      OrderResponse,
      ClientSelect
    },

    mixins: [validationMixins],

    data: () => {
      return {
        domainName: '',
        authInfo: null,
        result: '',
        isLoading: false,
        error: false,
        isLoadingRegistry: false,
        additionalError: [],
        clientId: null
      }
    },

    validations: {
      domainName: {
        required
      },
      clientId: {
        required: requiredIf (function () { return !this.hasClient })
      }
    },

    computed: {
      ...mapGetters ({
        hasClient: 'auth/hasClient'
      })
    },

    watch: {
      domainName () {
        this.additionalError = []
      }
    },

    methods: {
      ...mapActions ({
        fetchData: 'request/fetchData'
      }),

      reset () {
        this.domainName = ''
        this.authInfo = null
        this.$v.$reset ()
      },

      setError (error) {
        this.result = error
        this.error = true
      },

      submit () {
        if (!this.$v.$invalid) {
          this.isLoadingRegistry = true
          this.additionalError = []

          this.fetchData ({
            op: 'registry/forDomain',
            params: {
              name: this.domainName
            },
            cb: data => {
              if (data.registryId) {
                this.isLoading = true
                this.error = false
                this.result = ''

                this.fetchData ({
                  op: 'domain/inquire',
                  params: {
                    domainName: this.domainName,
                    authInfo: this.authInfo,
                    ...(!this.hasClient ? {clientId: this.clientId} : {})
                  },
                  cb: data => {
                    this.result = data.payloadResult
                  },
                  cbError: (data, next) => {
                    if (data.result.find (
                      (e) => e.code === PROCESSING_ERROR && e.params?.property === PAYLOAD_PROPERTY)
                    ) {
                      this.setError (data.errorData)
                    } else {
                      next (data)
                    }
                  },
                  cbFinal: () => {
                    this.isLoading = false
                  }
                })
              } else {
                this.additionalError = [this.$t ('view.invalidTld')]
              }
            },
            cbFinal: () => {
              this.isLoadingRegistry = false
            }
          })
        } else {
          this.$v.$touch ()
        }
      }
    }
  }
</script>
